
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
        alignItems: 'flex-start',
        justifyContent:'center',
        paddingLeft: '18%',
        width: '100%',
    },

    background: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        resizeMode: 'cover',
        
    },

    filterBackground: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
       
        backgroundColor: '#C4C4C4B2'
        
    },

    textLight: {
        fontFamily: 'Roboto-Thin',
        fontSize: 24,
        color: '#ffffff',
        marginBottom: 10,
    },
    textMedium: {
        fontFamily: 'Roboto-Regular',
        fontSize: 48,
         color: '#ffffff'
    },
    textBold: {
        fontFamily: 'Roboto-Black',
        fontSize: 80,
        color: '#333333'
    },


    logoParque: {
        height:360,
        width: 750,
        resizeMode: 'contain'
    }
  });
  