import { Image,  ScrollView, View } from 'react-native';
import Header from '../../components/Header';
import Landing from './Landing';
import Project from './Project';
import { styles } from './styles';
import Support from './Support';
import Progress from './Progress';
import ImageFooter from '../../assets/images/footer.png';
import ImageFooterMobile from '../../assets/images/footerMobile.png';

import {  useRef } from 'react';
import { usePositions } from '../../hooks/positions';
import { useDimensions } from '../../hooks/dimensions';
import HeaderMobile from '../../components/HeaderMobile';

export default function Home() {
  const scrollRef = useRef(null);
  const { setScrollPosition } = usePositions();

  const {orientation} = useDimensions()



  return (
    <>
      <ScrollView   
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        onScroll={(e) => {
          setScrollPosition(e.nativeEvent.contentOffset.y);
        }} 
        ref={scrollRef}
       
        style={{height: '100vh'}}
      >
        <Landing />
        <Project />
        <Support />
        <Progress />

        {orientation === 'web' ? 
        <>
        <View style={{ marginTop: 80, height: 20, width: '100%', backgroundColor: '#eeeeee' }} />
          <View style={{paddingHorizontal: '12%'}}>
            <Image source={ImageFooter} style={styles.footer} />
        </View>

        </>
        : 
        <>       
        <View style={{ marginTop: 80, height: 20, width: '100%', backgroundColor: '#eeeeee' }} />
          <Image source={ImageFooterMobile} style={styles.footerMobile} />
        </>
        }
        
      </ScrollView>


      {orientation === 'web' ? <Header ref={scrollRef}/> : <HeaderMobile ref={scrollRef}/>}


    </>
  );
}
