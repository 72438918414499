
import { View, Text, } from 'react-native';

import { styles } from './styles';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';



export default function MonthButton({text, onPress}) {
  const [textSize, setTextSize] = useState(0)


  return (
   <TouchableOpacity onPress={onPress} style={{ width: textSize + 1,alignItems: 'flex-start' }}>

    <View style={styles.viewLine}/>
      <Text
         onLayout={(e)=>{
       
          setTextSize(e.nativeEvent.layout.width)
        }}  
      
      style={styles.textTitle}>{text}</Text>

    <View style={styles.viewLine}/>
   </TouchableOpacity>
      
  );
}

