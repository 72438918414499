
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
        width: 230,
        height: 260,
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },

    gradientContainer: {
        height: 80,
        width: '100%',
        justifyContent:'center',
        alignItems: 'center',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },

    textTitle: {
     fontFamily: 'Roboto-Medium',
     color: 'white',
     fontSize:26
    },

    textValue: {
        color: '#AFABAB',
        fontFamily: 'Roboto-Medium',
        fontSize: 26,
        marginBottom: 50,
    },

    textSaiba: {
        color: '#757575',
        fontFamily: 'Roboto-Medium',
        fontSize: 16,
    }

    
  });
  