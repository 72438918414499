
import { View, Text,Image, useWindowDimensions } from 'react-native';

import { styles } from './styles';
import { useState } from 'react';


import MonthButton from './MonthButton';

import Agosto01 from '../../../assets/images/agosto01.jpeg'
import Agosto02 from '../../../assets/images/agosto02.jpeg'
import Agosto03 from '../../../assets/images/agosto03.jpeg'
import Agosto04 from '../../../assets/images/agosto04.jpeg'
import Agosto05 from '../../../assets/images/agosto05.jpeg'
import Agosto06 from '../../../assets/images/agosto06.jpeg'
import Agosto07 from '../../../assets/images/agosto07.jpeg'
import Agosto08 from '../../../assets/images/agosto08.jpeg'

import Setembro01 from '../../../assets/images/setembro01.png'
import Setembro02 from '../../../assets/images/setembro02.png'

import Outubro00 from '../../../assets/images/outubro00.png'
import Outubro01 from '../../../assets/images/outubro01.png'
import Outubro02 from '../../../assets/images/outubro02.png'
import Outubro03 from '../../../assets/images/outubro03.png'
import Outubro04 from '../../../assets/images/outubro04.png'
import Outubro05 from '../../../assets/images/outubro05.png'


import { usePositions } from '../../../hooks/positions';
import { useDimensions } from '../../../hooks/dimensions';


const fotosAgosto = [
  Agosto01,
  Agosto02,
  Agosto03,
  Agosto04,
  Agosto05,
  Agosto06,
  Agosto07,
  Agosto08
]
const fotosSetembro = [
  Setembro01,
  Setembro02,
]
const fotosOutubro = [
  Outubro00,
  Outubro01,
  Outubro02,
  Outubro03,
  Outubro04,
  Outubro05,
]

export default function Progress() {

  const [selectedMonth, setSelectedMonth] = useState('agosto')
  const {setPositions} = usePositions()
  const {width} = useWindowDimensions()

  const {orientation} = useDimensions()

  const padding = width * 0.24
  const CARD_WIDTH = 240

  const GAP = (width - (padding + CARD_WIDTH * 4)) / 3



  return (
   <View 
   onLayout={(e)=>{setPositions((prevState)=> {return {...prevState,progress: e.nativeEvent.layout.y}})}}
   style={styles.container}>
      <Text style={[styles.textTitle,{
        fontSize: orientation === 'web' ? 80 : 30
      }]}>Andamento da obra</Text>
    
    <View style={styles.viewButtons}>
      <MonthButton onPress={()=>{setSelectedMonth('agosto')}} text={'AGOSTO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('setembro')}} text={'SETEMBRO 2024'}/>
      <MonthButton onPress={()=>{setSelectedMonth('outubro')}} text={'OUTUBRO 2024'}/>
    </View>


    <View style={[orientation === 'web' ? styles.viewFotos : styles.viewFotosMobile ,{gap: GAP - 1}]}>
      {selectedMonth === 'agosto' &&    <> {fotosAgosto.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

      {selectedMonth === 'setembro' &&  <> {fotosSetembro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}

{selectedMonth === 'outubro' &&  <> {fotosOutubro.map((item,index)=>{
        return(
          <Image style={orientation === 'web' ? styles.image: {height: width - 10, width: width - 10, marginBottom: 5,}} key={index} source={item}/>
        )
      })}</>}
    </View>
   
   </View>
      
  );
}

