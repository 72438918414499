import * as React from "react"
import Svg, { Path } from "react-native-svg"
const Menu = () => (
  <Svg
  
  width="32" 
  height="22"
  xmlns="http://www.w3.org/2000/svg" fill="none">
    <Path
      fill="#4F4F4F"
      d="M30.311 8.889H1.69C.756 8.889 0 9.645 0 10.578v.178c0 .932.756 1.688 1.689 1.688H30.31c.933 0 1.689-.756 1.689-1.688v-.178c0-.933-.756-1.69-1.689-1.69ZM30.311 17.778H1.69c-.933 0-1.689.756-1.689 1.689v.178c0 .932.756 1.688 1.689 1.688H30.31c.933 0 1.689-.756 1.689-1.688v-.178c0-.933-.756-1.69-1.689-1.69ZM30.311 0H1.69C.756 0 0 .756 0 1.689v.178c0 .932.756 1.689 1.689 1.689H30.31c.933 0 1.689-.757 1.689-1.69V1.69C32 .756 31.244 0 30.311 0Z"
    />
  </Svg>
)
export default Menu
