
import { View, Text, } from 'react-native';

import { styles } from './styles';
import { useState } from 'react';

import CardSponsor from '../../../components/CardSponsor';

import { useWindowDimensions } from 'react-native';
import { usePositions } from '../../../hooks/positions';
import { useDimensions } from '../../../hooks/dimensions';


export default function Suport() {

  const [textSize, setTextSize] = useState(0)
  const {setPositions} = usePositions()

  const {orientation} = useDimensions()

  const {width} = useWindowDimensions()

  const padding = width * 0.24
  const CARD_WIDTH = 230



  return (
   <View
   onLayout={(e)=>{setPositions((prevState)=> {return {...prevState,suport: e.nativeEvent.layout.y}})}}
   style={styles.container}>
      <View style={
        {alignItems: orientation === 'web' ? 'flex-start' : 'center', 
        width:  orientation === 'web' ? textSize + 1 : '100%', 
        }}>
          <Text 
          onLayout={(e)=>{
            setTextSize(e.nativeEvent.layout.width)
          }} 
          style={[styles.textTitle,{
            fontSize: orientation === 'web' ? 80 : 30
          }]}>Como Apoiar</Text>
        
          <View style={styles.underlineTitle}/>

     
      </View>
      <Text style={styles.textDescription}>
          O patrocínio pode ser feito através do incentivo fiscal da Lei Rouanet, ou através de verba direta de marketing.{'\n'}
          O projeto conta com quatro cotas de patrocínio, com suas respectivas contrapartidas.  
      </Text>

      <View style={orientation === 'web' ? styles.viewCards : styles.viewCardsMobile}>
      <CardSponsor 
        onPress={()=>{window.open('https://firebasestorage.googleapis.com/v0/b/sitequattro-2c26e.appspot.com/o/pdfPinda%2FCOTA%20DIAMANTE.pdf?alt=media&token=497e2621-8c8b-4436-b845-bd87fd5cbcb7')}}
        value={'1.500.000,00'} 
        title={'DIAMANTE'} 
        colors={['#62AFC1', '#A4DFEC']}
        />
      <CardSponsor
        onPress={()=>{window.open('https://firebasestorage.googleapis.com/v0/b/sitequattro-2c26e.appspot.com/o/pdfPinda%2FCOTA%20OURO.pdf?alt=media&token=12cccc68-056f-4be0-9c76-80536a69f920')}}
        value={'500.000,00'} 
        title={'OURO'} 
        colors={['#C3A540', '#F7EDA1']}
      />
      <CardSponsor 
        onPress={()=>{window.open('https://firebasestorage.googleapis.com/v0/b/sitequattro-2c26e.appspot.com/o/pdfPinda%2FCOTA%20PRATA.pdf?alt=media&token=620fcdf6-c373-4310-b74f-1edd5998632a')}}
        value={'250.000,00'} 
        title={'PRATA'} 
        colors={['#999999', '#DADADA']}
        />
      <CardSponsor
        onPress={()=>{window.open('https://firebasestorage.googleapis.com/v0/b/sitequattro-2c26e.appspot.com/o/pdfPinda%2FCOTA%20BRONZE.pdf?alt=media&token=4214e8f5-a04c-4862-984e-5782b63a1054')}}
        value={'50.000,00'} 
        title={'BRONZE'} 
        colors={['#CD7F32', '#FDB875']}
      />
      </View>

      <Text style={styles.textFisica}>
        Cota Pessoa Física
      </Text>

      <View style={styles.viewFisica}>
          <Text style={styles.textInside}>
          Cota destinada a Pessoas Físicas que declarem o imposto de renda no FORMULÁRIO COMPLETO. Elas podem direcionar até <Text stlye={[styles.textInside,{fontFamily: 'Roboto-Bold'}]}>6%</Text> do seu imposto devido ao projeto. Como contrapartida, o nome do doador será exibido em uma placa no centro cultural.{'\n'}{'\n'}Não há um valor pré-definido, sendo de livre escolha da pessoa.
          </Text>
      </View>

   </View>
      
  );
}

