
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
        
        width: '100%',
        paddingHorizontal: '12%',
        marginTop: 100,
    },

    textTitle: {
        color: '#333333',
        fontFamily: 'Roboto-Black',
        fontSize: 80,
    },

    underlineTitle: {
        width: '100%',
        height: 2,
        backgroundColor:'#c4c4c4',
        marginBottom: 20,
        marginTop: 5,
    },

    buttonMore: {
        height: 70,
        width: 200,
        backgroundColor: '#333333',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
    },


    textButton: {
        fontFamily: 'Roboto-Regular',
        color: '#ffffff',
        
    },
    textDescription: {
        color: '#000000',
        fontFamily: 'Roboto-Thin',
        fontSize: 18,
        marginBottom: 30,
    },

    textDescriptionBold: {
        color: '#000000',
        fontFamily: 'Roboto-Medium',
        fontSize: 18,
    },

    textTitleGray: {
        fontFamily: 'Roboto-Medium',
        color: '#4f4f4f',
        fontSize: 50,
    },

    viewFirstStep: {
      
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    viewImages: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 560,
      
    },

    viewImagesMobile: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    
      
    },

    image1: {
        height: 345,
        width: 270,
        marginRight: 20,
    },
    image2: {
        height: 265,
        width: 270,
        resizeMode: 'contain',
        marginBottom: 20,
    },
    image3: {
        height: 200,
        width: 270,
        resizeMode: 'contain'
    },

    viewSecondStep: {
        marginTop: 50,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    image4: {
        height: 345,
        width: 270,
        marginLeft: 20,
    },
    image5: {
        height: 265,
        width: 270,
        resizeMode: 'contain',
        marginBottom: 20,
    },
    image6: {
        height: 200,
        width: 270,
        resizeMode: 'contain'
    },

    imageMobile: {
        height: 200,
        width: 250,
        marginBottom: 20,
        resizeMode: 'cover'
    }

  });
  