
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
 

    text: {
     fontFamily: 'Roboto-Regular',
     fontSize: 16,
     letterSpacing: 7,
    }
  });
  