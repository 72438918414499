import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { DimensionProvider } from "./dimensions";
import { PositionsProvider } from "./positions";

const Provider = ({ children }) => (
  <SafeAreaProvider>
    <PositionsProvider>
      <DimensionProvider>
      {children}
      </DimensionProvider>
    </PositionsProvider>
  </SafeAreaProvider>
);

export default Provider;
