
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
 
    
    footer: {
        height: 400,
        width: '100%',
        resizeMode: 'contain',
    },

    footerMobile: {
        height: 1000,
        width: '100%',
        resizeMode: 'contain',
    }

   
  });
  