import * as React from "react"
import Svg, { Path } from "react-native-svg"
const Close = () => (
  <Svg 
    width="26" 
    height="27"
  xmlns="http://www.w3.org/2000/svg" fill="none" >
    <Path
      fill="#4F4F4F"
      d="M24.377 22.433 4.137 2.194a1.689 1.689 0 0 0-2.388 0l-.126.126c-.66.66-.66 1.729 0 2.388l20.24 20.24c.659.659 1.728.659 2.388 0l.126-.126c.66-.66.66-1.73 0-2.389Z"
    />
    <Path
      fill="#4F4F4F"
      d="M21.862 1.401 1.623 21.641c-.66.659-.66 1.728 0 2.388l.126.125c.66.66 1.729.66 2.389 0L24.377 3.915c.66-.66.66-1.728 0-2.388l-.126-.126a1.689 1.689 0 0 0-2.389 0Z"
    />
  </Svg>
)
export default Close
