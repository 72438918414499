
import { View } from 'react-native';
import ButtonHeader from './ButtonHeader';
import { styles } from './styles';
import { usePositions } from '../../hooks/positions';
import { forwardRef } from 'react';



const Header = forwardRef(function Header(props,ref) {
  const { positions } = usePositions();


  return (
    <View style={styles.container}>
    <ButtonHeader 
    onPress={()=> {
      ref.current?.scrollTo({
      x: 0,
      y: positions.project - 150,
      animated: true})}} 
    title='O PROJETO'
    />

    <ButtonHeader
      onPress={()=> {
        ref.current?.scrollTo({
        x: 0,
        y: positions.suport - 150,
        animated: true})}} 
      title='COMO APOIAR'
      />

    <ButtonHeader 
      onPress={()=> {
        ref.current?.scrollTo({
        x: 0,
        y: positions.progress - 150,
        animated: true})}} 
      title='ANDAMENTO DA OBRA'
      />
  </View>
  )
})

export default Header





