
import { Pressable, View, Animated } from 'react-native';

import { styles } from './styles';
import { usePositions } from '../../hooks/positions';
import { forwardRef, useCallback, useState } from 'react';
import Menu from '../../assets/svg/Menu';
import Close from '../../assets/svg/Close'; 
import ButtonHeaderMobile from './ButtonHeaderMobile';




const HeaderMobile = forwardRef(function Header(props,ref) {
  const { positions } = usePositions();

  const [viewHeight] = useState(new Animated.Value(70))
  const [isOpen, setIsOpen] = useState(false)


  const handleMenuPress = useCallback(()=>{
    Animated.timing(viewHeight, {
      toValue: !isOpen ? 250 : 70,
      duration: 400,
      useNativeDriver: true
    }).start(()=>{setIsOpen(!isOpen)})
  },[isOpen, viewHeight])



  return (
    <Animated.View style={[styles.container,{
      height: viewHeight
    }]}>
      <Pressable style={{position: 'absolute', left: 20, top: 25}} onPress={()=>{handleMenuPress()}}>
        {!isOpen ? <Menu/> : <Close/>}
      </Pressable>

      {isOpen && 
      <View style={styles.viewContent}>
          <ButtonHeaderMobile
    onPress={()=> {
      ref.current?.scrollTo({
      x: 0,
      y: positions.project - 150,
      animated: true})}} 
    title='O PROJETO'
    />

    <ButtonHeaderMobile
      onPress={()=> {
        ref.current?.scrollTo({
        x: 0,
        y: positions.suport - 150,
        animated: true})}} 
      title='COMO APOIAR'
      />

    <ButtonHeaderMobile 
      onPress={()=> {
        ref.current?.scrollTo({
        x: 0,
        y: positions.progress - 150,
        animated: true})}} 
      title='ANDAMENTO DA OBRA'
      />
      </View>
      }
   
  </Animated.View>
  )
})

export default HeaderMobile





