import React, { createContext, useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

const DimensionContext = createContext();

export const useDimensions = () => {
  return useContext(DimensionContext);
};

export const DimensionProvider = ({ children }) => {
  const [orientation, setOrientation] = useState('web');

  const updateDimensions = () => {
    const { height, width } = Dimensions.get('window');
    setOrientation(height > width ? 'mobile' : 'web');
  };

  useEffect(() => {
    updateDimensions(); 

    const subscription = Dimensions.addEventListener('change', updateDimensions);

    return () => {
      subscription?.remove(); // Cleanup listener on unmount
    };
  }, []);

  return (
    <DimensionContext.Provider value={{ orientation }}>
      {children}
    </DimensionContext.Provider>
  );
};