
import {Text, Image, View, Dimensions } from 'react-native';

import { styles } from './styles';

import Cover from '../../../assets/images/cover.png'
import { useDimensions } from '../../../hooks/dimensions';

import LogoParque from '../../../assets/images/LogoParque.png'

export default function Landing() {

  const windowHeight = Dimensions.get('window').height
  const {orientation} = useDimensions()

  return (
   <View style={[styles.container,{height: windowHeight}]}>


   
    
      <Image source={Cover} style={styles.background}/>
      <View style={styles.filterBackground}/>

      <Text style={[styles.textLight,{
        fontSize: orientation === 'web' ? 24 : 16,
        transform: orientation === 'web' ? [{translateX: -100}, {translateY: 100}] : null
      }]}>Ministério da Cultura apresenta:</Text>
      <Image source={LogoParque} style={[styles.logoParque,{
         width: orientation === 'web' ? 750 : 375,
         height: orientation === 'web' ? 360 : 180 }]}/>
   </View>
      
  );
}

