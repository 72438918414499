import * as React from "react"
import Svg, { Path } from "react-native-svg"

const ArrowLeft = ({color='#333'}) => (
<Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={9}
    fill="none"
   
  >
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M0 4.5A.5.5 0 0 1 .5 4h16a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5Z"
      clipRule="evenodd"
    />
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M12.646.646a.5.5 0 0 1 .708 0L17.207 4.5l-3.853 3.853a.5.5 0 0 1-.708-.707L15.793 4.5l-3.147-3.147a.5.5 0 0 1 0-.707Z"
      clipRule="evenodd"
    />
  </Svg>

)
export default ArrowLeft
