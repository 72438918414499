
import { useEffect } from 'react';
import Home from './src/pages/Home';
import * as SplashScreen from 'expo-splash-screen'

import { useFonts } from 'expo-font';


import Provider from './src/hooks';
export default function App() {

  const [loaded, error] = useFonts({

    'Roboto-Thin': require('./src/assets/fonts/Roboto-Thin.ttf'),
    'Roboto-Regular': require('./src/assets/fonts/Roboto-Regular.ttf'),
    'Roboto-Medium': require('./src/assets/fonts/Roboto-Medium.ttf'),
    'Roboto-Bold': require('./src/assets/fonts/Roboto-Bold.ttf'),
    'Roboto-Black': require('./src/assets/fonts/Roboto-Black.ttf'),
    'Roboto-Condensed': require('./src/assets/fonts/Roboto-Condensed.ttf'),

  })
  const crypto = require('crypto-browserify');
  useEffect(()=>{
    if(loaded || error) {
      SplashScreen.hideAsync();
    }
  },[loaded, error])

  
  if (!loaded && !error) {
    return null;
  }


  return (
    <Provider>
      <Home/>
    </Provider>
 
  );
}
