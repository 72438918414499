
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#f4f4f4',
      height: 70,
      position: 'absolute',
      top: 0,
      alignItems: 'center',
      gap: 80,
      width: '100%',
      flexDirection: 'row',
      paddingHorizontal: '15%',
      justifyContent: 'space-evenly',
      width: '100%',
    },
  });
  