
import { Text, TouchableOpacity,View } from 'react-native';

import { styles } from './styles';

import { LinearGradient } from 'expo-linear-gradient';
import Plus from '../../assets/svg/Plus';
import { useDimensions } from '../../hooks/dimensions';



export default function CardSponsor({title, colors, value, onPress}) {

  const {orientation}  = useDimensions()


  return (
    <TouchableOpacity onPress={onPress} style={[styles.container,{
      marginBottom: orientation === 'mobile' ? 30 : 0
      }]}>
      <LinearGradient 
        start={[1,1]}
        end={[1,1]}
      colors={colors}
      style={styles.gradientContainer}>
        <Text style={styles.textTitle}>
            {title}
        </Text>

      </LinearGradient>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Text style={styles.textValue}>
                R$ {value}
            </Text>

            <View style={{flexDirection: 'row', alignItems:'center', alignSelf: 'center', bottom: 10, position: 'absolute'}}>
                <Text style={styles.textSaiba}>Saiba mais</Text>
                <Plus/>

            </View>
        </View>

        
   
    </TouchableOpacity>
  )
}

