
import { Text, TouchableOpacity } from 'react-native';

import { styles } from './styles';

export default function ButtonHeader({title, onPress}) {

  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  )
}

