import * as React from "react"
import Svg, { Path } from "react-native-svg"

const Plus = ({color='#333'}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  
  >
    <Path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M16 6.667v18.666M6.667 16h18.666"
    />
  </Svg>

)
export default Plus
