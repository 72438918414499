
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
 

    textTitle: {
        fontSize: 18,
        fontFamily: 'Roboto-Condensed',
        color: '#333333'
    },

    viewLine: {
        width: '100%',
        height: 1,
        backgroundColor: '#333333'
    }
  

  });
  