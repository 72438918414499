
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
        marginTop: 80,
        paddingHorizontal: '12%',
        width: '100%',
    },

    textTitle: {
        color: '#333333',
        fontFamily: 'Roboto-Black',
        fontSize: 80,
    },

    underlineTitle: {
        width: '100%',
        height: 2,
        backgroundColor:'#c4c4c4',
        marginBottom: 20,
        marginTop: 5,
    },

    textDescription: {
        fontFamily: 'Roboto-Thin',
        fontSize: 16,
        textAlign: 'left',
        marginBottom: 50,
    },

    viewCards: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent:'space-between',
    },

    viewCardsMobile: {
        alignItems: 'center'
        
    },

    textFisica: {
        fontFamily:'Roboto-Medium',
        fontSize: 42,
        color:'#4f4f4f',
        marginTop: 50,
        marginBottom: 20,
    },

    viewFisica: {
        borderWidth: 2,
        borderColor: '#c4c4c4',
        padding: 20,
        width: '100%',

    },

    textInside: {
        fontSize: 16,
        color: '#1e1e1e',
        fontFamily: 'Roboto-Thin'
    }


  });
  