
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#f4f4f4',
      height: 70,
      position: 'absolute',
      justifyContent: 'center',
      top: 0,
      alignItems: 'flex-start',
      paddingHorizontal: '5%',
      width: '100%',
    },

    viewContent: {
      height: '60%',

      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  });
  